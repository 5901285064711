<template>
  <div class="addbanner-continer">
    <div class="addbanner-center">
<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
    <el-form-item label="banner名称" prop="name">
    <el-input v-model="ruleForm.name"></el-input>
  </el-form-item>
    <el-form-item label="活动时间" required>
    <el-col :span="11">
      <el-form-item prop="date1">
        <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.date1" style="width: 100%;"></el-date-picker>
      </el-form-item>
    </el-col>
    <el-col class="line" :span="2">-</el-col>
    <el-col :span="11">
      <el-form-item prop="date2">
        <el-time-picker placeholder="选择时间" v-model="ruleForm.date2" style="width: 100%;"></el-time-picker>
      </el-form-item>
    </el-col>
  </el-form-item>
     <el-form-item label="banner上传" prop="name">
   <el-upload
  class="upload-demo"
  action="https://jsonplaceholder.typicode.com/posts/"
  :on-preview="handlePreview"
  :on-remove="handleRemove"
  :before-remove="beforeRemove"
  multiple
  :limit="3"
  :on-exceed="handleExceed"
  :file-list="fileList">
  <el-button size="small" >点击上传</el-button>
  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过50kb</div>
</el-upload>
  </el-form-item>
   <el-form-item label="banner链接" prop="lz">
    <el-input v-model="ruleForm.lz"></el-input>
  </el-form-item>
  <el-form-item label="活动形式">
    <el-input type="textarea" maxlength="200" v-model="ruleForm.text"></el-input>
  </el-form-item>
  <el-form-item label="banner开关">
  <el-checkbox v-model="checked"></el-checkbox>
  </el-form-item>
    <el-form-item>
    <el-button type="primary" @click="onSubmit">保存</el-button>
    <el-button>取消</el-button>
  </el-form-item>
  </el-form>
    </div>
  </div>
</template>

<script>
  export default {
 data() {
      return {
        ruleForm: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          lz:'',
          text:''
        },
        checked:'',
        rules: {
          name: [
            { required: true, message: '请输入banner名称', trigger: 'blur' },
            { min: 1, max: 16, message: '长度在 1 到 16 个字符', trigger: 'blur' }
          ]
        }
      };
    },
     methods: {
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log(file);
      },
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${ file.name }？`);
      }
    }
  }
</script>

<style  scoped>

.addbanner-center{
width: 430px;
height: 500px;
margin: auto;
}
</style>